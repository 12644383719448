////
/// @group _settings/
////

// sass-lint:disable no-important
// sass-lint:disable no-universal-selectors

@mixin _helpers-spaces-generate ($gap, $value, $mod: "") {
	._#{$mod}sv-#{$gap} {
		margin-bottom: -$value !important;

		> * {
			padding-bottom: $value !important;
		}
	}

	._#{$mod}sh-#{$gap} {
		margin-right: (-$value / 2) !important;
		margin-left: (-$value / 2) !important;

		> * {
			padding-right: ($value / 2) !important;
			padding-left: ($value / 2) !important;
		}
	}

	._#{$mod}s-#{$gap} {
		margin-right: (-$value / 2) !important;
		margin-left: (-$value / 2) !important;
		margin-bottom: -$value !important;

		> * {
			padding-right: ($value / 2) !important;
			padding-left: ($value / 2) !important;
			padding-bottom: $value !important;
		}
	}
}

/// Хелперы отступов между блоками
/// @requires {mixin} media
/// @requires {mixin} _helpers-spaces-generate
/// @param {Map} $screens [()] - карта брейкпоинтов, для которых нужно сделать хелперы
/// @param {Map} $gutters [()] - карта отступов, для которых нужно сделать хелперы
@mixin helpers-space ($screens: (), $gutters: ()) {
	@each $gap, $value in $gutters {
		@include _helpers-spaces-generate($gap, $value);

		@each $point, $size in $screens {
			@include media($size) {
				@include _helpers-spaces-generate($gap, $value, $point + "-");
			}
		}
	}
}
