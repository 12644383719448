////
/// @group essentials/
////

* { // sass-lint:disable-line no-universal-selectors
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: none;
	outline: 0;
}

html {
	position: relative;
	font-family: $font-family-main;
	color: $color-black;
	font-size: 16px;
	width: 100%;
	min-width: $screen-st;
	height: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	overflow-x: hidden;

	//@include media(ms) {
	//	font-size: 14px;
	//}
	//
	//@include media(md) {
	//	font-size: 15px;
	//}
	//
	//@include media(lg) {
	//	font-size: 16px;
	//}

	&.is-overflow {
		overflow: hidden;

		body {
			overflow: hidden;
		}
	}

	&.mfp-is-opened {
		overflow: hidden;
		margin-right: 0 !important; // sass-lint:disable-line no-important

		&.no-mobile {
			&.cssscrollbar {
				padding-right: 8px;
			}

			&.no-cssscrollbar {
				padding-right: 17px;
			}
		}
	}
}

body {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0;
}

a {
	text-decoration: none;
}

ins {
	text-decoration: none;
}

svg {
	display: block;
	width: 100%;
	height: 100%;
	fill: inherit;
	stroke: inherit;

	use {
		pointer-events: none;
	}
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

picture {
	display: block;
	max-width: 100%;
	width: auto;
}

ul,
ol {
	list-style: none;
	margin: 0;
}

i {
	font-style: normal;
}

b {
	font-weight: normal;
}

label {
	font-weight: normal;
}

main,
article,
aside,
footer,
header,
nav,
section {
	display: block;
}

audio {
	&[controls] {
		display: block;
		width: 100%;
	}
}

video,
iframe {
	max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: medium;
	font-weight: normal;
	margin: 0;
}

button {
	font-family: inherit;
}
