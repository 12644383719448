////
/// @group _settings/
////

/// @param {String} $mod [""] - название брейкпоинта с разделителем
/// @access private
@mixin _helpers-color-generate($mod: "", $colors: ()) {
	$props: (
		color: color,
		bgcolor: background-color,
		fill: fill
	);

	@each $color, $value in $colors {
		@each $key, $prop in $props {
			._#{$mod + $key + '-' + $color} {
				#{$prop}: $value !important; // sass-lint:disable-line no-important
			}
		}
	}
}

/// Хедперы цветов элементов
/// @param {Map} $colors [()] - карта цветов, для которых нужно сделать хелперы
@mixin helpers-color ($colors: ()) {
	@include _helpers-color-generate('', $colors);
}
