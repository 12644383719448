////
/// @group _settings/
////

@mixin ellipsis ($max-width: 100%) {
	display: block;
	max-width: $max-width;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
