////
/// @group _settings/
////

// sass-lint:disable no-universal-selectors

/// Flex сетка
/// @require {mixin} media
/// @param {Size} $columns [12] - количество колонок
/// @param {Map} $screens [()] - карта брейкпоинтов, для которых нужно сделать хелперы
/// @param {Map} $gutters [()] - карта отступов, для которых нужно сделать хелперы
@mixin helpers-grid($columns: 12, $screens: (), $gutters: ()) {
	// контейнер
	.grid {
		display: flex;
		flex-wrap: wrap;

		@for $i from 1 through $columns {
			$width: 100% / $i;

			// Создаем классы по схеме .grid--3
			&--#{$i} {
				& > * {
					width: $width;
				}
			}

			// с медиа-запросами по схеме .grid--lg-3
			@each $point, $size in $screens {
				@include media($size) {
					&--#{$point}-#{$i} {
						& > * {
							width: $width;
						}
					}
				}
			}
		}
	}

	// ячейки
	.gcell {
		min-width: 0;
		min-height: 1px;
		display: inline-block;

		// Создаем классы по схеме .gcell--3
		@for $i from 1 through $columns {
			$width: $i * 100% / $columns;

			&--#{$i} {
				width: $width;
			}
		}

		@each $point, $size in $screens {
			@include media($size) {
				// с медиа-запросами по схеме .gcell--md
				&--#{$point} {
					width: auto;
				}

				// с медиа-запросами по схеме .gcell--md-3
				@for $i from 1 through $columns {
					$width: $i * 100% / $columns;

					&--#{$point}-#{$i} {
						width: $width;
					}
				}
			}
		}
	}
}
